const translation = {
  home: {
    hero: {
      1: "Hvert øye er et",
      2: "mesterverk",
      3: "Høyoppløselig Iris Fotografering på et øyeblikk",
      4: "Fra 599,-",
    },
    button: {
      findUs: "Finn Oss",
      moreAboutConcept: "Mer om konseptet",
      openMap: "Se på kart",
      book: "Bestill time",
      seeMoreQuestions: "Se flere spørsmål",
    },
    conceptText: {
      1: {
        title: "1. Foto",
        text: "Med vårt toppmoderne utstyr er prosessen rask, enkel og helt komfortabel. Det er bare et vanlig bilde, men resultatet er ekstraordinært.",
      },
      2: {
        title: "2. Redigering",
        text: "Våre eksperter optimaliserer bildet ditt nøye, eliminerer eventuelle lysrefleksjoner og setter iris mot en kontrastfylt svart bakgrunn. Dette tar ca 30 minutter.",
      },
      3: {
        title: "3. Trykk",
        text: "Ditt personlige irisportrett blir deretter brakt til liv, trykt i uovertruffen kvalitet på det materialet og i den størrelsen du foretrekker.",
      },
    },
    findUs: {
      title: "Finn Oss",
      monday: "Mandag 10:00 - 15:00",
      weekdays: "Tirsdag - Onsdag 10:00 - 18:00",
      thursday: "Torsdag 12:00 - 20:00",
      friday: "Fredag 12:00 - 18:00",
      saturday: "Lørdag 10:00 - 16:00",
      sunday: "Søndag stengt",
      alwaysBook:
        "Vi anbefaler alltid å booke time, hvis ikke kan det være vi ikke er åpent.",
    },
  },
  FAQ: {
    title: "FAQ",
    title2: "Lurer du på noe?",
    faqs: [
      {
        question: "Må jeg booke på forhånd?",
        answer:
          "Niks! Vi tar drop-in, så det er bare å komme innom. Du kan også booke time på nett for å være sikker på at du ikke må vente.",
      },
      {
        question: "Selger dere gavekort?",
        answer: "Såklart! Sving innom butikken så ordner vi det for deg.",
      },
      {
        question: "Er det ubehagelig?",
        answer:
          "Nei! Vi er aldri borti øyet ditt. Det er et helt vanlig fotografisk kamera som tar bildet.",
      },
      {
        question: "Tar det lang tid?",
        answer:
          "Nei! Det hele gjøres på 15 minutt til en halvtime. Ventetid overstiger sjeldent 15 minutt, og vi har en hyggelig lounge.",
      },
      {
        question: "Får jeg produktet med en gang?",
        answer:
          "Ja! Vi printer og rammer inn bildet på timen om du vil det. For større produkter i andre materialer får du det i posten innen ca 2 uker.",
      },
    ],
  },
  concept: {
    title: "Konsept",
    photo:
      "Ved hjelp av topp-moderne utstyr for foto, fanger vi det unike i hvert øye.",
    edit: "I redigeringsfasen bruker vi en spesiell teknikk for å fremheve øyets detaljer og farger uten å endre på det naturlige utseendet.",
    print:
      "Ved hjelp av toppmoderne utstyr, leverer vi prints i eksepsjonell kvalitet i ønsket størrelse og materiale.",
    wall: "Dekorer dine vegger med et personlig og betydningsfullt kunstverk som varer livet ut eller gi det samme i gave.",
    title1: "Eviggjør din iris",
    text1:
      "Ved hjelp av topp-moderne utstyr for foto og print, fanger vi det unike i hvert øye og eviggjør det i et kunstverk i høyeste kvalitet.",
    title2: "Del det med nære og kjære",
    text2:
      "En betydningsfull opplevelse for deg selv, med familie eller venner. Ta med noen du er glad i og del opplevelsen!",
    title3: "Gave med mening",
    text3:
      "Du kan også gi Instant Iris i gave i form av gavekort, som du får kjøpt hos oss i butikk. Gi en gave som varer.",
  },
  bookAppointment: {
    title: "Kontakt Oss",
    dropIn: "Spør oss om du lurer på noe!",
    description:
      "Hos Instant Iris setter vi pris på både spontanitet og forberedelse. Selv om vår ventetid sjelden overstiger 20 minutter, anbefaler vi at du kontakter oss for å avtale en tid. Dette sikrer at du får den beste opplevelsen uten ventetid. Men hvis spontanitet er mer din stil, er du selvfølgelig alltid velkommen til å svinge innom når det passer deg best.",
    tellUs:
      "Spør oss hva som helst, så kommer vi tilbake til deg så snart som mulig.",
    openingHours: {
      title: "Åpningstider",
      monday: "Mandag 10:00 - 15:00",
      weekdays: "Tirsdag - Onsdag 10:00 - 18:00",
      thursday: "Torsdag 12:00 - 20:00",
      friday: "Fredag 12:00 - 18:00",
      saturday: "Lørdag 10:00 - 16:00",
      sunday: "Søndag Stengt",
    },
    location: {
      title: "Sandviken",
      address: "Steinkjellergaten 2, 5003 Bergen",
    },
    form: {
      book: "Kontakt Oss",
      name: "Navn",
      email: "Email",
      message: "Melding",
      exampleMessage: "Hvilket produkt anbefaler du for en familie på 5?",
      button: "Send inn",
    },
  },
  FAQs: {
    title: "Forberedelser",
    button1: "Forberedelser",
    button2: "På timen",
    button3: "Etter timen",
    section_1: [
      {
        question: "Må jeg bestille time?",
        answer:
          "Nei! Vi tar drop-ins, så det er bare å komme innom. Du kan også booke på nett hvis du vil være sikker på å slippe ventetid.",
      },
      {
        question: "Hvor er dere?",
        answer: "Du finner oss i Steinkjellergaten 2, 5003 Bergen.",
      },
      {
        question: "Hvor lang tid tar det?",
        answer:
          "Selve bildet er gjort på et blunk. Det kan ta opp til en time før bildet er ferdig printet og rammet inn, avhengig av kompleksitet på designet. Ventetid er sjeldent over 15 minutt.",
      },
      {
        question: "Hva koster det?",
        answer:
          "Det varier veldig ut ifra antall personer, størrelsen på bildet og hvilket materiale du ønsker. Vi har produkter ned til 499kr!",
      },
      {
        question: "Hvilke betalingsmetoder har dere?",
        answer: "Vi tar både kort og kontant, men vi foretrekker kort!",
      },
      {
        question: "Er det noen aldersbegrensninger?",
        answer:
          "Nei! Men barn bør helst være noen år gammel, siden det krever å sitte stille med hodet støttet mens bildet blir tatt.",
      },
      {
        question: "Hvor mange kan være på ett bilde?",
        answer:
          "Opp til 5 stk! Hvis det er mer enn det så kan dere kontakte oss så finner vi en løsning.",
      },
    ],
    section_2: [
      {
        question: "Er det ubehagelig?",
        answer:
          "Nei! Vi er aldri borti øyet ditt. Det er et helt vanlig kamera.",
      },
      {
        question: "Får jeg produktet med en gang?",
        answer:
          "Ja! Vi printer og rammer inn bildet på timen om du vil det. For større produkter i andre materialer får du det i posten innen ca 2 uker.",
      },
      {
        question: "Hva med kontaktlinser?",
        answer:
          "Du kan bruke kontaktlinser, men vi anbefaler at de tas ut under bildet. Dette er for å forhindre gjenskinn som kan redusere kvaliteten på bildet.",
      },
      {
        question: "Hvilke størrelser og materiale har dere?",
        answer: `Vi har en rekke forskjellige produkter i størrelser fra 20x20cm til 150cm, i både kvadrat, rektangulært og sirkel. Se mer om hva vi tilbyr på "Konsept" siden.`,
      },
      {
        question: "Hva med smittefare?",
        answer:
          "Vi følger alle smittevernsregler, og desinfiserer utstyr mellom hver kunde.",
      },
    ],
    section_3: [
      {
        question: "Hvor lang tid tar det å levere?",
        answer:
          "Fotoprints blir ferdig på timen, mens større produkter tar ca 2 uker.",
      },
      {
        question: "Bildet har mangler eller er skadet, hva gjør jeg?",
        answer:
          "Kontakt oss så finner vi en løsning! Om det viser seg at det er vår eller leverandøren sin feil så får du selvfølgelig et nytt produkt.",
      },
    ],
  },
  Gallery: {
    title: "Galleri",
  },
};

export default translation;
